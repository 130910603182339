'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.incidents.factory:IntercadIncidentCommentsFactory

 # @description

###
class IntercadIncidentCommentsFactory
  ### @ngInject ###
  constructor: (
    UiHelpers
    MundoMap
    ModelHelpers
    $translate
    Restangular
  ) ->
    IntercadIncidentCommentsBase = {}
    IntercadIncidentCommentsBase.newIntercadIncidentComment = (incidentId, comment) ->
      new Promise (resolve, reject) ->
        url = "lpa/intercad/incidents/#{incidentId}/comments"
        Restangular.all(url).post(comment)
          .then (result) ->
            resolve(result)
          , (error) ->
            reject(error)

    return IntercadIncidentCommentsBase
  
angular
  .module 'mundoAdmin.incidents'
  .factory 'IntercadIncidentComments', IntercadIncidentCommentsFactory

